import React from 'react' 

export function InfoBox(props){
    return(
        <div className="space-y-2">
            <h3 className="text-2xl">
                {props.title}
            </h3>
            <i>
                {props.details}
            </i>
            <p className='flex'>
                {props.content}
            </p>
        </div>
    )
}

export function EduBox(props){
    return(
        <div className="space-y-2 space-x-2">
            <div className="flex flex-row flex-wrap justify-start">
               
                <div className="flex-grow">
                    <h3 className="text-2xl flex-col">
                        {props.school}
                    </h3>
                    <i className="flex-col">
                        {props.major}
                    </i>
                </div>

                

                <div className="md:text-right flex-grow">
                    <p className='text-lg flex-col'>
                        {props.location}
                    </p>
                    <p className="flex-col md:justify-self-end">
                        {props.time}
                    </p>
                </div>
                
                
            </div>
            
            <div>
                {props.comments}
            </div>
        </div>
    )
}

export function ExpListContainer(props){
    return(
        <div className="space-y-10">
            {props.children}
        </div>
    )
}
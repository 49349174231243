import React from "react"
import { Layout } from "../components/layout"
import { RowStrip } from "../components/rows/rowstrip"
import { RowItem } from "../components/rows/rowitem"
import { RowItemHeader } from "../components/rows/rowitemheader"
import { ExpListContainer, InfoBox, EduBox } from "../components/Resume/resumeexpbox"
import { ReturnBar } from "../components/Navbar/ReturnBar"

const ResumePage = () => {
    return(
        <Layout title={"Austin Loza - Resume"} hicontrast={true}>
            <ReturnBar color={"red"} />
            <h1 className="text-center text-6xl mb-3 font-bold">Austin Loza</h1>
            <p className="text-center text-xl mb-5">austinloza24@gmail.com</p>
            <RowStrip>
                <RowItem>
                    <RowItemHeader>Skills</RowItemHeader>
                    
                    <div className="flex flex-row flex-wrap justify-center space-y-5 md:space-y-0 md:space-x-5 lg:justify-evenly" >
                        
                        <div className="flex-col">
                            <h3 className="text-2xl">Programming Languages</h3>
                            <ul className="list-disc px-16">
                                <li>JavaScript</li>
                                <li>Python</li>
                                <li>SQL</li>
                                <li>HTML</li>
                                <li>CSS</li>
                                <li>C/C++</li>
                                <li>C#</li>
                                <li>Java / Scala</li>
                                <li>x86 ASM</li>
                            </ul>
                        </div>

                        <div className="flex-col">
                            <h3 className="text-2xl">Tools and Frameworks</h3>
                            <ul className="list-disc px-16">
                                <li>React</li>
                                <li>Redux</li>
                                <li>Git</li>
                                <li>NodeJS</li>
                                <li>MongoDB</li>
                                <li>PostgreSQL</li>
                                <li>Linux/Unix</li>
                                <li>TensorFlow</li>
                                <li>PyTorch</li>
                                <li>NumPy</li>
                                <li>WPF</li>
                                <li>QT</li>
                                <li>Sketch</li>
                                <li>Adobe Suite</li>
                            </ul>
                        </div>
                    </div>
                </RowItem>

                <RowItem>
                    <RowItemHeader>Projects</RowItemHeader>
                    <ExpListContainer>
                        
                        <InfoBox 
                            title={"FastPhoto"} 
                            details={"Made With: C#, WPF"}
                            content={"A Windows program that applies PNG overlays to photos to be printed. \
                            It monitors a folder for new photos and applies the overlay as it comes in. \
                            These output photos can be displayed in a kiosk mode or they can be printed through the main interface. This project has been used in production."} 
                        />

                        <InfoBox
                            title={"Master's Thesis"}
                            details={"Made With: Python, PyTorch, Jupyter"}
                            content={"My thesis is a comparison of DeepMind’s Differentiable Neural Computer with traditional machine learning methods. \
                            Achieved near state-of-the-art performance on MNIST task."}
                        />
                    </ExpListContainer>
                </RowItem>
            </RowStrip>

            <RowStrip>
                
                <RowItem>
                    <RowItemHeader>Experience</RowItemHeader>
                    <ExpListContainer>
                        <InfoBox 
                            title={"Dun & Bradstreet"}
                            details={"Software Engineer II (2021 - Present)"}
                            content={"I work with Node and React across the front and back ends to develop and maintain a customer-facing dashboard to \
                            simplify customers' usage of our products as well as increase their likelihood of purchasing \
                            more of our other products and services. I also began a full-stack project that provided various internal \
                            tools that facilitated test account creation, app monitoring, and more."}
                        />

                        <InfoBox 
                            title={"Knockout Photo"}
                            details={"Full Stack Developer (2018 - 2021)"}
                            content={"I used HTML/CSS, ReactJS, Redux, and JavaScript on the frontend to create and maintain user-facing web pages and employee-facing tools. \
                            On the backend, I used NodeJS/Express and MongoDB to construct Knockout Photo’s APIs that handle customer payments, \
                            invoicing, account registration/login, \
                            and order booking."}
                        />

                        <InfoBox 
                            title={"UCSB Enterprise Technology Services"}
                            details={"Service Desk - Intern  (2016 - 2018)"}
                            content={"Provided efficient IT support as a member of a team, answering client questions, \
                            as well as maintaining enterprise hardware, and testing software before rolling out to users."}
                        />
                    </ExpListContainer>
                </RowItem>
                
                <RowItem>
                    <RowItemHeader>Education</RowItemHeader>
                    <ExpListContainer>
                        <EduBox 
                            school={"California State University, Fullerton"}
                            major={"Master of Science - Computer Science"}
                            location={"Fullerton, CA"}
                            time={"2018 - 2019"}
                        />

                        <EduBox 
                            school={"University of California, Santa Barbara"}
                            major={"Bachelor of Science – Computer Science"}
                            location={"Santa Barbara, CA"}
                            time={"2015 - 2018"}
                        />
                    </ExpListContainer>

                </RowItem>
            </RowStrip>
            <div className="min-w-full flex justify-evenly">
                <button className="transition ease-linear duration-300 bg-gray-300 text-black hover:text-white hover:bg-black p-5 rounded-md" onClick={e => {window.location.href = "/"}}>Return to home</button>
            </div>
        </Layout>
    )
}

export default ResumePage
import React from 'react' 

export function RowItem(props){
    return(
        <div className='lg:w-2/4 py-7 px-5 flex flex-col justify-center items-center'>
            {props.children}
        </div>
    )
}

export function RowSubItem(props){
    return(
        <></>
    )
}
import React from 'react' 

export function RowItemHeader(props){
    return(
        <h2 className="text-3xl text-center mb-5 font-bold">
            {props.children}
        </h2>
    )
}

export function RowSubItemHeader(props){
    return(
        <></>
    )
}